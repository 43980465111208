import revive_payload_client_9NKVoZFWHI from "/opt/build/repo/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@20.17.22_db0@0.2.4_eslint@8.57.1_ioredis@_10fbe5347a7217ad617713b79ed40342/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_ZTOUYdj2I9 from "/opt/build/repo/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@20.17.22_db0@0.2.4_eslint@8.57.1_ioredis@_10fbe5347a7217ad617713b79ed40342/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_XDXmEHBOwa from "/opt/build/repo/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@20.17.22_db0@0.2.4_eslint@8.57.1_ioredis@_10fbe5347a7217ad617713b79ed40342/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_WzQAbepBSp from "/opt/build/repo/node_modules/.pnpm/nuxt-site-config@2.2.21_magicast@0.3.5_vite@6.2.0_@types+node@20.17.22_jiti@2.4.2_sass@_677ebcf2a7bbd0ee9d447686105c22a0/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_Kik35rjJk5 from "/opt/build/repo/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@20.17.22_db0@0.2.4_eslint@8.57.1_ioredis@_10fbe5347a7217ad617713b79ed40342/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_8yEVsXaebF from "/opt/build/repo/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@20.17.22_db0@0.2.4_eslint@8.57.1_ioredis@_10fbe5347a7217ad617713b79ed40342/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_EAOxS0wg2i from "/opt/build/repo/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@20.17.22_db0@0.2.4_eslint@8.57.1_ioredis@_10fbe5347a7217ad617713b79ed40342/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_x0uRoyGeRf from "/opt/build/repo/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@20.17.22_db0@0.2.4_eslint@8.57.1_ioredis@_10fbe5347a7217ad617713b79ed40342/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_9ZnTraEbEN from "/opt/build/repo/node_modules/.pnpm/@pinia+nuxt@0.4.11_magicast@0.3.5_typescript@5.8.2_vue@3.5.13_typescript@5.8.2_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/opt/build/repo/.nuxt/components.plugin.mjs";
import prefetch_client_T4MjPkxtBX from "/opt/build/repo/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@20.17.22_db0@0.2.4_eslint@8.57.1_ioredis@_10fbe5347a7217ad617713b79ed40342/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_khUdprSDFF from "/opt/build/repo/node_modules/.pnpm/@zadigetvoltaire+nuxt-gtm@0.0.13_magicast@0.3.5_nuxt@3.15.4_@parcel+watcher@2.5.1_@type_7c266aca785d3d40e00958e1b4351200/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import nuxt_plugin_iME3KC628D from "/opt/build/repo/node_modules/.pnpm/nuxt-delay-hydration@1.3.8_magicast@0.3.5/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.js";
import templates_pwa_client_PgQuOsnzsQ_alPwFfAnkL from "/opt/build/repo/.nuxt/templates.pwa.client.PgQuOsnzsQ.ts";
import plugin_9TMvO5YDwE from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+apollo@5.0.0-alpha.8_magicast@0.3.5_rollup@4.34.9_typescript@5.8.2_vue@3.5.13_typescript@5.8.2_/node_modules/@nuxtjs/apollo/dist/runtime/plugin.mjs";
import switch_locale_path_ssr_YgKtEYWHB5 from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+i18n@8.5.6_magicast@0.3.5_rollup@4.34.9_vue@3.5.13_typescript@5.8.2_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_2gKBNS3pgm from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+i18n@8.5.6_magicast@0.3.5_rollup@4.34.9_vue@3.5.13_typescript@5.8.2_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import apollo_IRWTEOPleP from "/opt/build/repo/plugins/apollo.js";
import directives_8CcCirWtnE from "/opt/build/repo/plugins/directives.ts";
export default [
  revive_payload_client_9NKVoZFWHI,
  unhead_ZTOUYdj2I9,
  router_XDXmEHBOwa,
  _0_siteConfig_WzQAbepBSp,
  payload_client_Kik35rjJk5,
  navigation_repaint_client_8yEVsXaebF,
  check_outdated_build_client_EAOxS0wg2i,
  chunk_reload_client_x0uRoyGeRf,
  plugin_vue3_9ZnTraEbEN,
  components_plugin_KR1HBZs4kY,
  prefetch_client_T4MjPkxtBX,
  plugin_khUdprSDFF,
  nuxt_plugin_iME3KC628D,
  templates_pwa_client_PgQuOsnzsQ_alPwFfAnkL,
  plugin_9TMvO5YDwE,
  switch_locale_path_ssr_YgKtEYWHB5,
  i18n_2gKBNS3pgm,
  apollo_IRWTEOPleP,
  directives_8CcCirWtnE
]