
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexFCBv1Fu6ayMeta } from "/opt/build/repo/pages/activity/[parent]/[slug]/index.vue?macro=true";
import { default as indexCvqKFUPAkdMeta } from "/opt/build/repo/pages/activity/[parent]/index.vue?macro=true";
import { default as indexlaFeZuYZkUMeta } from "/opt/build/repo/pages/index.vue?macro=true";
import { default as newsletter4iem4JeVdNMeta } from "/opt/build/repo/pages/newsletter.vue?macro=true";
import { default as component_45stubJN68g2UhyBMeta } from "/opt/build/repo/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@20.17.22_db0@0.2.4_eslint@8.57.1_ioredis@_10fbe5347a7217ad617713b79ed40342/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubJN68g2UhyB } from "/opt/build/repo/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@20.17.22_db0@0.2.4_eslint@8.57.1_ioredis@_10fbe5347a7217ad617713b79ed40342/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "activity-parent-slug___it",
    path: "/:parent()/:slug()",
    component: () => import("/opt/build/repo/pages/activity/[parent]/[slug]/index.vue")
  },
  {
    name: "activity-parent___it",
    path: "/:parent()",
    component: () => import("/opt/build/repo/pages/activity/[parent]/index.vue")
  },
  {
    name: "index___it",
    path: "/",
    component: () => import("/opt/build/repo/pages/index.vue")
  },
  {
    name: "newsletter___it",
    path: "/newsletter",
    component: () => import("/opt/build/repo/pages/newsletter.vue")
  },
  {
    name: component_45stubJN68g2UhyBMeta?.name,
    path: "/sitemap.xml",
    component: component_45stubJN68g2UhyB
  }
]